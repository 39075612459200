@import "../base/var";
@import "../base/fonts";
@import "../base/buttons";

footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: $dark-blue-footer;
  @media screen and (max-width: $breakDesktop) {
    flex-direction: column-reverse;
  }
  .informations {
    display: flex;
    width: 80%;
    padding: 4% 4%;
    flex-direction: column;
    align-content: space-around;
    @media screen and (max-width: $breakDesktop) {
      width: 100%;
      padding: 6% 6%;
    }
    p {
      font-size: 1.125em;
      line-height: 1.5625em;
      font-family: semi-bold;
      color: $white;
      margin-bottom: 10px;
    }
    .inexplore-logo {
      width: 100%;
      a {
        .logo {
          width: 235px;
          height: 54px;
        }
      }
      p {
        letter-spacing: 0.125em;
        color: $light-grey-footer;
      }
    }
    .socials {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 2em 0;
      @media screen and (max-width: $breakSmallTab) {
        flex-direction: column;
      }
      .social-links {
        width: 60%;
        @media screen and (max-width: $breakSmallTab) {
          width: 100%;
          margin: 0 0 1em 0;
        }
        ul > li {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 5px;
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.875em;
            line-height: 1.5625em;
            font-family: semi-bold;
            color: $light-grey-footer;
            text-decoration: none;
            .icon {
              margin-bottom: 5px;
            }
          }
          a:hover {
            .icon {
              filter: invert(51%) sepia(67%) saturate(6733%) hue-rotate(207deg)
                brightness(100%) contrast(101%);
            }
            color: $blue;
          }
        }
      }

      .newsletter {
        width: 100%;
        margin: 1em 0;
        @media screen and (min-width: $breakSmallTabMin) {
          width: 41%;
          margin: 0;
        }
        .form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 50px;
          background-color: $white;
          border-radius: 30px;
          input {
            margin-left: 10px;
            border-color: $white;
            height: 40px;
            width: 80%;
            font-family: semi-bold;
            color: $blue-marina;
            outline: none;
            box-shadow: none;
            border: none;
          }
        }
      }
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @media screen and (min-width: $breakSmallTabMin) {
        width: 100%;
      }
      margin-bottom: 2em;
      ul {
        padding-left: 2px !important;
        li {
          font-size: 1em;
          line-height: 1.5625em;
          font-family: medium;
          color: $light-grey-footer;
          margin-bottom: 10px;
          a {
            font-size: 1em;
            line-height: 1.5625em;
            font-family: medium;
            color: $light-grey-footer;
            text-decoration: none;
            .icon {
              margin-top: 5px;
            }
            path {
              fill: $light-grey-footer;
            }
          }
          a:hover {
            .icon {
              filter: invert(51%) sepia(67%) saturate(6733%) hue-rotate(207deg)
                brightness(100%) contrast(101%);
            }
            color: $blue;
          }
          ul > li {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 10px;
            a {
              .icon {
                margin-bottom: 5px;
              }
              path {
                fill: $light-grey-footer;
              }
            }
            a:hover {
              .icon {
                filter: invert(51%) sepia(67%) saturate(6733%)
                  hue-rotate(207deg) brightness(100%) contrast(101%);
              }
              color: $blue;
            }
          }
        }
      }
    }
    .copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        text-align: center;
        font-size: 1em;
        font-family: medium;
        color: $light-grey-footer;
      }
      img {
        margin-top: 1em;
      }
    }
  }
}

.contacts {
  background-color: $blue-footer-contact;
  padding: 6% 6%;

  display: flex;
  flex-direction: column;
  @media screen and (min-width: $breakSmallTab) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media screen and (min-width: $breakDesktop) {
    flex-direction: column;
    width: 20%;
    justify-content: flex-start;
    padding: 4% 2.5%;
  }
  .sav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .status {
      font-size: 1.125em;
      line-height: 1.5625em;
      font-family: semi-bold;
      color: $white;
      margin-bottom: 2%;
    }
    span {
      font-size: 0.75em;
      color: $dark-blue-footer;
      font-family: medium;
      margin-left: 20px;
      border-radius: 12px;
      padding: 5px 10px;
    }
    .open {
      background-color: $light-green;
    }
    .close {
      background-color: $light-red;
    }
    p {
      font-size: 1em;
      margin-top: 0;
      margin-bottom: 0;
      font-family: medium;
      color: $light-grey-footer;
    }
  }
  ul > li {
    width: 100%;
    margin-bottom: 5%;
    @media screen and (min-width: $breakDesktop) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10%;
    }
    a {
      font-size: 1.125em;
      font-family: semi-bold;
      color: $white;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      width: 100%;
      text-decoration: none;
      span {
        @media screen and (min-width: $breakSmallTab) {
          margin-left: 10px;
        }
        min-width: 50px;
        height: 50px;
        background-color: $blue-contact-options;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .disabled {
      font-size: 1.125em;
      font-family: semi-bold;
      color: $light-grey-footer;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      width: 100%;
      span {
        @media screen and (min-width: $breakSmallTab) {
          margin-left: 10px;
        }
        min-width: 50px;
        height: 50px;
        background-color: $blue-contact-options;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
      }
    }
  }
}
