button,
a.button,
.button {
  width: 18.7rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include radius(2.5rem);
  font-size: 1.6rem;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  @include transition(all, 0.5s, ease);
  box-shadow: none;
  text-shadow: none;
  border: none;
  overflow: hidden;
  font-family: 'BrandingSemibold', Arial, sans-serif;
  font-weight: normal;
  padding: 1.6rem 0;
  z-index: 998;
  text-align: center;  
  color: $black; 
  background-color: $sec-c;  
  &:focus,
  &.active,
  &:hover {
    background-color: #FFDB80;
    box-shadow: 0px 0px 12px 0px #FFDB80;
    cursor: pointer;
    outline: none;
    @include transition(all, 0.5s, ease);   
  }
  span {
    padding: 0 !important;
    margin: 0 !important;
  } 
  &.btn-sec {
    background-color: $last-c;
    &:focus,
    &.active,
    &:hover {
      background-color: #ACE8D7;
      box-shadow: 0px 0px 12px 0px #B0EEDD;
    }       
  } 
  &.btn-green-dark {
    background-color: #75a6a0;
    color: $white;
    @include p-sim(2rem);
    width: auto;
    &:focus,
    &.active,
    &:hover {
      background-color: #ACE8D7;
      color: $white;
      box-shadow: 0px 0px 12px 0px #B0EEDD;
    }       
  } 
  &.btn-table {
    display: table;
    @include m-sim(auto);
  }    
}