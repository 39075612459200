@import "../base/var";
@import "../base/fonts";

.main {
  display: flex;
  min-height: 80vh;
  @media screen and (max-width: $breakSmallTab) {
    min-height: 70vh;
  }
}
.main-auth {
  justify-content: center;
  align-items: center;
  background-image: url("../../images/main-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
}
form {
  display: flex;
}
.full-width {
  width: 100%;
}
