@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Jost:wght@300&family=Red+Hat+Display:wght@300;400;500;600;700;800;900&family=Roboto:wght@500&display=swap');
* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
html {
	height: 100%;
	padding: 0;
	font-size: 62.5%;
}
body {
	overflow: scroll;
	overflow-x: hidden; 
	font-size: 1.6rem;
	line-height: 1.7rem;
	height: 100%;
	padding: 0;
	max-height: 99999.9rem;
	color: $white;
	background-color: #302d46;
	font-weight: 400;
	font-family: 'BrandingMedium', Arial, sans-serif;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}
.container {
	width:100%;
	max-width: 100%;
	position: relative;
	overflow: hidden;
	padding: 0;		
	@media only screen and (min-width:991px) and (max-width:1299px) {
		width:inherit;
		max-width: inherit;
	}
	@media only screen and (max-width:991px) {
		width:100%;
		max-width:100%;
	}		
}
section {
	position: relative;
}
.max-width {
	position: relative;
	max-width: 129.6rem;
	width: 100%;
	margin:0 auto;	
	@media #{$wmax} {
		max-width: 100%;
		@include p-sim(2rem);
	}
	@media #{$w380} {
		@include p-sim(1rem);
	}						
}
a {
	position: relative;
	text-decoration: none;
	display: block;
	cursor: pointer;
	color: $white;
	&:hover,
	&:focus { 
		text-decoration:none;
		color: $white;
	}	
	&.underline {
		display: inline-block;
		text-decoration: underline;
	}
}
ul,
li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
img,
svg {
	border: 0;
	display: block;
}
p,
figure {
	margin: 0;
	padding: 0;
}
strong {
	font-weight: 700;
}


