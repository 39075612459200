@font-face {
  font-family: 'BrandingBold';
  src: url(../../fonts/BrandingBold.eot);
  src: url(../../fonts/BrandingBold.eot?#iefix) format('embedded-opentype'),
       url(../../fonts/BrandingBold.otf) format('otf'),
       url(../../fonts/BrandingBold.svg#BrandingBold) format('svg'),
       url(../../fonts/BrandingBold.ttf) format('truetype'),
       url(../../fonts/BrandingBold.woff) format('woff'),
       url(../../fonts/BrandingBold.woff2) format('woff2');
}
@font-face {
  font-family: 'BrandingSemibold';
  src: url(../../fonts/BrandingSemibold.eot);
  src: url(../../fonts/BrandingSemibold.eot?#iefix) format('embedded-opentype'),
       url(../../fonts/BrandingSemibold.otf) format('otf'),
       url(../../fonts/BrandingSemibold.svg#BrandingSemibold) format('svg'),
       url(../../fonts/BrandingSemibold.ttf) format('truetype'),
       url(../../fonts/BrandingSemibold.woff) format('woff'),
       url(../../fonts/BrandingSemibold.woff2) format('woff2');
}
@font-face {
  font-family: 'BrandingMedium';
  src: url(../../fonts/BrandingMedium.eot);
  src: url(../../fonts/BrandingMedium.eot?#iefix) format('embedded-opentype'),
       url(../../fonts/BrandingMedium.otf) format('otf'),
       url(../../fonts/BrandingMedium.svg#BrandingMedium) format('svg'),
       url(../../fonts/BrandingMedium.ttf) format('truetype'),
       url(../../fonts/BrandingMedium.woff) format('woff'),
       url(../../fonts/BrandingMedium.woff2) format('woff2');
}