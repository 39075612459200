@import "../base/var";
@import "../base/fonts";
@import "../base/buttons";

.login-wrapper {
  width: 45em;
  margin: 3em 0 5em 0;
  background-color: $very-light-grey;
  min-height: 60vh;
  box-shadow: -1px 3px 29px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @media screen and (max-width: $breakSmallTab) {
    margin: 0;
    min-height: 70vh;
    width: 100%;
    box-shadow: none;
  }
  .login-container {
    margin: 0 6.25em 4em 6.25em;
    @media screen and (max-width: $breakSmallTab) {
      margin: 0 2em 4em 2em;
    }
    .auth-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 2.5em 0;
      .icon {
        width: 80px;
        height: 80px;
        fill: green;
        margin-bottom: 1.5em;
      }
      .close-page {
        margin-top: 1em;
        width: 75%;
      }
      .log-out {
        width: 75%;
        margin: 1em 0;
      }
    }
    .form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      .forgot-password-link {
        font-family: medium;
        font-size: 0.875em;
        color: $grey;
      }
      .forgot-password-link:hover {
        color: $blue-marina;
      }
    }
    .link {
      font-family: medium;
      font-size: 1em;
      text-align: center;
      color: $dark-blue;
      margin-top: 2.5em;
    }
    .link:hover {
      color: $blue-marina;
    }
    .submit-login {
      margin: 2em 0;
    }
    .submit-reset-password {
      margin: 2em 0;
      @media screen and (max-width: $breakSmallTab) {
        margin: 1em 0;
      }
    }
  }
}
