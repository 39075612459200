@import "./var";

/* buttons styles */

.btn-gradient {
  min-width: 25%;
  height: 3.125em;
  display: block;
  border: none;
  color: $white;
  position: relative;
  background-image: linear-gradient(
    90deg,
    $light-red 30%,
    $orange 60%,
    $yellow 100%
  );
  font-family: semi-bold;
  font-size: 1em;
  cursor: pointer;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
  }
}
.btn-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: $dark-blue;
  z-index: -1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.35);
}
.btn-gradient:hover {
  background-image: linear-gradient(90deg, $white, $white, $white);
}
.btn-gradient:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(90deg, $light-red 0%, $orange 50%, $yellow 100%);
  z-index: -1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.35);
}
.btn-footer-newsletter {
  width: 20%;
  height: 45px;
  display: block;
  border: none;
  background-color: $dark-blue-footer;
  margin-right: 3px;
  border-radius: 30px;
}
.btn-footer-newsletter:hover {
  background-color: $blue;
}
.link-button {
  width: 100%;
  text-decoration: none;
}
.btn-clear {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  text-decoration: underline;
  color: $blue-marina;
}
.btn-clear-dark-blue {
  font-family: medium;
  font-size: 1em;
  color: $dark-blue;
  cursor: pointer;
}
.btn-clear-dark-blue:hover {
  color: $blue-marina;
}
.btn-clear-small-grey {
  font-family: medium;
  font-size: 0.875em;
  color: $grey;
  cursor: pointer;
}
.btn-clear-small-grey:hover {
  color: $blue-marina;
}
.btn-outline-dark-blue {
  min-width: 25%;
  height: 3.125em;
  display: block;
  border: 2px solid $dark-blue;
  border-radius: 25px;
  color: $dark-blue;
  position: relative;
  font-family: semi-bold;
  font-size: 1em;
  background-color: $white;
  cursor: pointer;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
  }
}
.btn-outline-dark-blue:hover {
  color: $white;
  background-color: $dark-blue;
}
.btn-red {
  min-width: 25%;
  height: 3.125em;
  display: block;
  border: none;
  border-radius: 25px;
  color: $alert;
  position: relative;
  font-family: semi-bold;
  font-size: 1em;
  background-color: $alert-background;
  cursor: pointer;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
  }
}
.btn-red:hover {
  color: $white;
  background-color: $alert;
}
