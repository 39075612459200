@import "../base/var";
@import "../base/fonts";

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 10px;
  margin: 10px 0 0;
}
.radio-item input[type="radio"] {
  display: none;
}
.radio-item label {
  font-size: 1em;
  font-family: medium;
  color: $blue-radio-label;
}
.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 15px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid $dark-blue;
  background-color: transparent;
}
.radio-item input[type="radio"]:checked + label:after {
  border-radius: 10px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 14px;
  content: " ";
  display: block;
  background: $dark-blue;
}
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $dark-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $dark-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

