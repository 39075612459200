@import "variables.scss"; 
@import "../module-common/global/main.scss";
@import "../module-common/global/header.scss";
@import "../module-common/global/buttons.scss";
@import "../module-common/global/titles.scss";
@import "../module-common/fonts.scss";

.path-frontpage {
	.container {  
		.top-ticket {
			background-image: url(../../image/bg-main.png);
			@include cover();
			background-position: center 9.4rem;
			padding-bottom: 3.5rem;
			@media #{$w992} {
				@include p-sim(2rem);
				background-position: center 5.4rem;
			}			
			&:before {
				content:'';
				background-image: url(../../image/bande-or.png);
				@include w-h(278rem, .7rem);
				@include ab-bl50(0,139rem);
			}			
			>img {
				position: relative;
				margin: 0 auto 2rem auto;
				z-index: 1;
				@media #{$w480} {
					@include w-h(100%,auto);
				}					
			}
			.button {
				width: auto;
				@include p-sim(2rem);
			}
			h1 {
				position: relative;
				text-align: center;
				line-height: 3.2rem;
				margin-bottom: 3.5rem;
				@media #{$w480} {
					line-height: 3rem;
				}	
				strong {
					color: $sec-c;
					font-size: 4rem;
				}			
				&:before {
					@include ab-tl50(-17.5rem,63.6rem);
					content:'';
					background-image: url(../../image/star.png);
					@include w-h(127.2rem, 45.6rem);
				}				
				img {
					margin: 1.5rem auto;
					@media #{$w650} {
						@include w-h(100%,auto);
					}						
				}
				span {
					display: block;
					font-size: 2.6rem;						
					i {
						font-style: normal;
						color: $sec-c;
					}
				}
			}
			.rituel {
				padding-top: 15rem;
				margin-bottom: 2rem;
				@media #{$w992} {
					padding-top: 10rem;
				}				
				ul {
					display: flex;
					justify-content: center;
					align-items: center;
					li {
						&:nth-child(2) {
							@include m-sim(14rem);
							text-align: center;
							@media #{$w1200} {
								@include m-sim(10rem);
							}							
							@media #{$w992} {
								@include m-sim(1rem);
							}															
							img {
								@include m-sim(auto);
								@include w-h(51.7rem, 29.5rem);
								@media #{$w480} {
									@include w-h(100%,auto);
								}								
							}
							p {
								font-size: 2.4rem;
								margin-bottom: 2rem;
							}
						}
						&:last-child {
							text-align: center;
							p {
								margin-bottom: 2.1rem;
							}
						}
						&:first-child,
						&:last-child {
							@media #{$w768} {
								display: none;
							}							
						}
					}
				}
			}
		}		
		.special-edition {
			background-image: linear-gradient(to right, #2e2f41, #38374c, #423e58, #4e4663, #5a4e6e);
			padding: 6.5rem 0 7.5rem 0;
			@media #{$w650} {
				padding: 3.5rem 0;
			}			
			h2 {
				margin-bottom: 3.2rem;
				@media #{$w992} {
					margin-bottom: 3rem;
				}				
				span {
					color: $sec-c;
				}
			}
			strong {
				color: $sec-c;
			}
			.max-width {
				display: flex;
				justify-content: center;
				align-items: center;
				@media #{$w768} {
					display: block;
				}				
				.img {
					margin-right: 14.3rem;
					@media #{$w1200} {
						margin-right: 3rem;
					}
					@media #{$w768} {
						margin-bottom: 2rem;
						margin-right: 0;
					}					
					img {
						@media #{$w768} {
							@include m-sim(auto);
						}
						@media #{$w320} {
							@include w-h(100%,auto);
						}						
					}										
				}
				.text {
					width: 58.8rem;
					@media #{$w768} {
						width: 100%;
					}					
				}
			}
		}
		.partner {
			background-image: linear-gradient(to right, #2f3043, #39374e, #433f59, #4e4663, #5a4e6e);
			padding: 3.5rem 0;
			h2 {
				margin-bottom: 5rem;
				@media #{$w992} {
					margin-bottom: 3rem;
				}				
			}
			.max-width {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: row-reverse;
				@media #{$w1200} {
					justify-content: flex-start;
				}	
				@media #{$w768} {
					display: block;
				}							
				.text {
					width: 56.4rem;
					margin-right: 20.1rem;
					@media #{$w1200} {
						margin-right: 3rem;
					}	
					@media #{$w768} {
						margin: 0;
						width: 100%;
					}	
					>div {
						display: flex;
						justify-content: space-between;
						align-items: center;
						@media #{$w480} {
							display: block;
						}						
						img {
							margin-left: 1.5rem;
							@media #{$w480} {
								@include m-sim(auto);
								margin-bottom: 2rem;
							}								
						}
					}													
				}
				.img {
					@media #{$w768} {
						display: flex;
						justify-content: center;
						margin-bottom: 2rem;
					}					
					img {
						@media #{$w768} {
							@include m-sim(0);
						}	
						@media #{$w480} {
							@include w-h(100%, auto);
						}										
					}
				}
			}
		}
		.participate {
			padding: 6rem 0 7.5rem 0;
			background-image: linear-gradient(to right, #191a2a, #201f31, #272439, #2e2940, #362e47);
			@media #{$w650} {
				padding: 3.5rem 0;
			}			
			&:before {
				@include ab-bl(0,0);
				content:'';
				@include w-h(100vw,34.3rem);
				background-size: 100vw 26.4rem;
				background: url(../../image/vagues.png) bottom left no-repeat;
			}
			.button {
				width: 28.7rem;
				&.first-button {
					margin-bottom: 3rem;
				}
			}
			.max-width {
				display: flex;
				justify-content: space-between;
				flex-direction: row-reverse;
				@media #{$w992} {
					display: block;
				}				
				.text {
					width: 60.1rem;
					@media #{$wmax} {
						margin-right: 1.5rem;
					}	
					@media #{$w1200} {
						margin-right: 3rem;
					}	
					@media #{$w992} {
						width: 100%;
						margin-right: 0;
					}													
					h2 {
						margin-bottom: 4rem;
						@media #{$w992} {
							margin-bottom: 3rem;
						}						
					}
					strong {
						display: block;
						color: $sec-c;
						margin-bottom: .8rem;
					}
					a {
						margin-top: 1.5rem;
						&.underline {
							margin: 0;
						}
					}
				}
				.img {
					img {
						@media #{$w650} {
							@include w-h(100%,auto);
						}						
					}
				}
			}
		}		
		.by-three {
			background-image: linear-gradient(to right, #222337, #2c2b42, #37334e, #433b59, #4f4365);
			padding: 3.7rem 0 5.5rem 0;
			@media #{$w650} {
				padding: 3.5rem 0;
			}			
			h2 {
				text-align: center;
				margin-bottom: 8.4rem;				
			}
			.list {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin-bottom: 4rem;
				@media #{$w1200} {
					flex-wrap: inherit;
				}				
				@media #{$w768} {
					display: block;
				}					
				.item {
					@include w-33(1.3rem);
					background-image: linear-gradient(to bottom, #2f3043, #313245, #333348, #36354a, #38374d);
					text-align: center;
					padding: 0 3rem 3.5rem 3rem;
					@media #{$wmax} {
						@include p-sim(1.6rem);
					}	
					@media #{$w1200} {
						@include w-33(.7rem);
					}
					@media #{$w768} {
						display: flex;
						flex-wrap: wrap;
						width: 100%;
						margin-bottom: 10rem;
					}														
					&:nth-child(2) {
						@include m-sim(2rem);
						@media #{$w1200} {
							@include m-sim(1rem);
						}	
						@media #{$w768} {
							@include m-sim(0);
						}											
					}
					&:last-child {
						@media #{$w768} {
							margin-bottom: 2rem;
						}						
					}
					.medal {
						@include w-h(14rem,25.6rem);
						margin: -6.5rem auto 3.5rem auto;
					}
					h3 {
						margin-bottom: 1.5rem;
						@media #{$w768} {
							width: 100%;
						}						
						&.gold {
							color: $sec-c;
						}
						&.silver {
							color: $silver;
						}
						&.bronze {
							color: $bronze;
						}
					}
					.lot {
						height: 28rem;
						display: flex;
						justify-content: center;
						align-items: center;
						@media #{$w992} {
							height: 40rem;
						}	
						@media #{$w768} {
							width: 100%;
							height: inherit;
						}
						.cover-tiny {
							@include m-sim(auto);
							@include w-h(12.5rem,15.2rem);
						}											
						p,
						ul {
							margin-top: 2.5rem;
							font-size: 1.8rem;
							line-height: 2.1rem;
							li {
								color: $grey;
							}
						}
					}
				}
			}			
		}
		.formula {
			background-image: linear-gradient(to right, #222337, #2c2b42, #37334e, #433b59, #4f4365);
			padding: 8.3rem 0 7.5rem 0;
			@media #{$w650} {
				padding: 3.5rem 0;
			}			
			h2 {
				margin-bottom: 6rem;
				@media #{$w992} {
					margin-bottom: 3rem;
				}				
			}
			&:before {
				@include ab-tl(0,0);
				content:'';
				@include w-h(100vw,81rem);
				background-size: 100vw 81rem;
				background: url(../../image/bg-leaf.png) top left no-repeat;
			}
			.list {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				@media #{$w768} {
					display: block;
				}				
				.item {
					@include w-33(2rem);
					background-image: linear-gradient(to bottom, #36324d, #3b3652, #413958, #463d5d, #4c4162);
					padding: 4rem 2rem;
					@media #{$w1200} {
						@include w-33(.7rem);
					}	
					@media #{$w1200} {
						@include p-sim(1rem);
					}
					@media #{$w768} {
						width: 100%;
						margin-bottom: 2.5rem;
						@include p-sim(2rem);
					}
					@media #{$w480} {
						padding-top: 2.5rem;
						padding-bottom: 2.5rem;
					}																				
					&:nth-child(2) {
						@include m-sim(3rem);
						@media #{$w1200} {
							@include m-sim(1rem);
						}
						@media #{$w768} {
							@include m-sim(0);
						}												
					}
					figure {
						display: flex;
						justify-content: center;
						width: 100%;
						height: 5.7rem;
						margin-bottom: 6.5rem;
						@media #{$w480} {
							margin-bottom: 2.5rem;
						}						
					}
					h4 {
						margin-bottom: 4rem;
					}
					>p {
						min-height: 12rem;
						margin-bottom: 5rem;
						line-height: 2.5rem;
						@media #{$w1200} {
							min-height: 17.5rem;
						}
						@media #{$w992} {
							min-height: 20rem;
							margin-bottom: 3rem;
						}	
						@media #{$w768} {
							min-height: inherit;
							margin-bottom: 2rem;
						}																		
					}
					.price {
						margin-bottom: 3.4rem;
						font-size: 1.4rem;
						line-height: 2.5rem;
						span {
							display: block;
							color: $grey;
						}
						p {
							strong {
								color: $white;
								font-size: 3rem;
							}
						}
					}
					a {
						width: 100%;
					}
				}
			}						
		}
		.revue {
			background-color: #99d0c1;
			padding: 5.5rem 0 4.5rem 0;
			&:before {
				@include ab-tl(0,0);
				content:'';
				@include w-h(100vw,111.7rem);
				background-size: 100vw 111.7rem;
				background: url(../../image/bg-leaf-green.png) top left no-repeat;
			}			
			.max-width {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: row-reverse;	
				@media #{$w768} {
					display: block;
				}				
				.text {
					width: 69.1rem;
					margin-right: 7rem;
					@media #{$w1200} {
						margin-right: 3rem;
					}	
					@media #{$w768} {
						margin: 0;
						width: 100%;
					}					
					h2 {
						color: $white;
						margin-bottom: 4.3rem;
						@media #{$w992} {
							margin-bottom: 3rem;
						}							
					}
					p {
						width: 50.7rem;
						font-size: 1.6rem;
						line-height: 1.8rem;
						color: #181927;
						margin-bottom: 3.2rem;
						@media #{$w1200} {
							width: 100%;
						}						
					}
				}
				img {
					@media #{$w768} {
						@include m-sim(auto);
						margin-bottom: 2rem;
					}					
				}
			}
		}
		.cgv {
			background-image: linear-gradient(to right, #222337, #2c2b42, #37334e, #433b59, #4f4365);
			padding: 2.5rem 0;
			.max-width {
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				img {
					margin-right: 1rem;
				}
			}
		}		
	}
}
