/* responsive breakpoint */
$breakMobile: 520px;
$breakMobileMin: 521px;
$breakSmallTab: 750px;
$breakSmallTabMin: 751px;
$breakTab: 1024px;
$breakTabMin: 1025px;
$breakDesktop: 1230px;

/* colors */
$black: #000000;
$white: #ffffff;
$very-light-grey: #fcfcfd;
$light-grey: rgba(22, 42, 65, 0.04);
$grey: rgba(22, 42, 65, 0.5);
$grey-input-border: #d1d1d8;
$blue-marina: #363b5a;
$blue-radio-label: #332e45;
$blue: #1d73ff;
$blue-transparent: rgba(29, 115, 255, 0.3);
$input-label: rgba(51, 46, 69, 0.5);
$blue-contact-options: #1737cc;
$dark-blue: #162a41;
$dark-blue-footer: #0f1d2e;
$blue-footer-contact: rgba(23, 56, 204, 0.14);
$light-grey-footer: rgba(252, 252, 253, 0.5);
$light-green: #8cd867;
$light-red: #ee7992;
$light-red-title: #f07892;
$alert: #e94b32;
$alert-background: rgba(233, 75, 50, 0.08);
$orange: #f1ae65;
$yellow: #f4c54d;
$success-background: rgba(39, 174, 95, 0.3);
$green: rgba(39, 174, 95, 1);
