@import "../../base/var";
@import "../../base/fonts";

.title-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 3em 0 1em 0;
    @media screen and (max-width: $breakSmallTab) {
      margin: 4em 0 1em 0;
    }
  }
  h1 {
    font-family: bold;
    font-size: 1.625em;
    color: $dark-blue;
    margin-bottom: 0.625em;
    text-align: center;
  }
  h2 {
    font-family: medium;
    font-size: 1em;
    line-height: 1em;
    color: $grey;
    text-align: center;
    margin-bottom: 2em;
  }
}
