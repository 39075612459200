// COLORS
$white:#ffffff;
$black:#061636;
$main-c : #0D0D0D;
$sec-c : #F1C555;
$last-c: #99D0C1;
$grey: #9899a2;
$silver: #ECEBEC;
$bronze: #D2BC90;

// MEDIA QUERIES
$wmax: #{"only screen and (max-width:1300px)"};
$w1200: #{"only screen and (max-width:1199px)"};
$w992: #{"only screen and (max-width:991px)"};
$w768: #{"only screen and (max-width:767px)"};
$w650: #{"only screen and (max-width:650px)"};
$w480: #{"only screen and (max-width:480px)"};
$w380: #{"only screen and (max-width:380px)"};
$w320: #{"only screen and (max-width:320px)"};

//BORDER RADIUS KIT
@mixin radius($radius){
     -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
     border-radius: $radius;
}
//MULTIPLE BORDER RADIUS KIT
@mixin radius-x4($radiusTop, $radiusRight, $radiusBottom, $radiusLeft){
     -webkit-border-radius: $radiusTop $radiusRight $radiusBottom $radiusLeft;
     -moz-border-radius: $radiusTop $radiusRight $radiusBottom $radiusLeft;
     border-radius: $radiusTop $radiusRight $radiusBottom $radiusLeft;
}

// TRANSFORM
@mixin transform($degrees){
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -webkit-transform: rotate($degrees);
  transform: rotate($degrees);  
}
// BOX SHADOW KIT
@mixin box-shadow($string: 0 1px 3px 0 rgba(0, 73, 103, 0.2)){
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}

// HEIGHT LINEHEIGHT SIMILAIRES
@mixin h-lh-sim($height-lineheight){
  height:$height-lineheight;
  line-height: $height-lineheight;
}
// HEIGHT + LINEHEIGHT
@mixin h-lh($valHeight, $vallineHeight){
  height:$valHeight;
  line-height: $vallineHeight;
}
// WIDTH + HEIGHT
@mixin w-h($valWidth, $valHeight){
  width:$valWidth;
  height:$valHeight;
}
// WIDTH + HEIGHT SIMILARITIES
@mixin w-h-sim($width-height-sim){
  width:$width-height-sim;
  height:$width-height-sim;
}
// WIDTH + HEIGHT + LINE-HEIGHT
@mixin w-h-lh($valWidth, $valHeight, $vallineHeight){
  width:$valWidth;
  height:$valHeight;
  line-height: $vallineHeight;
}
// WIDTH + HEIGHT/LINE-HEIGHT SIMILARITIES
@mixin w-h-lh-sim($valWidth, $height-lineheight){
  width:$valWidth;
  @include h-lh-sim ($height-lineheight);
}

// PADDING LEFT = PADDING RIGHT
@mixin p-sim($padding-sim){
  padding-left:$padding-sim;
  padding-right: $padding-sim;
}

// MARGIN LEFT = MARGIN RIGHT
@mixin m-sim($margin-sim){
  margin-left:$margin-sim;
  margin-right: $margin-sim;
}

// CALCUL LARGEUR PAR 100
// exemple : ((53px))
@mixin w-100($widthCalc){
  width:calc#{"(100% - #{$widthCalc})"};
  width:-moz-calc#{"(100% - #{$widthCalc})"};
  width:-webkit-calc#{"(100% - #{$widthCalc})"};
  width:#{"calc(100% - #{$widthCalc})"};
  width:#{"-moz-calc(100% - #{$widthCalc})"};
  width:#{"-webkit-calc(100% - #{$widthCalc})"};
}
// CALCUL LARGEUR PAR 50
// exemple : ((53px))
@mixin w-50($widthCalc){
  width:calc#{"(50% - #{$widthCalc})"};
  width:-moz-calc#{"(50% - #{$widthCalc})"};
  width:-webkit-calc#{"(50% - #{$widthCalc})"};
  width:#{"calc(50% - #{$widthCalc})"};
  width:#{"-moz-calc(50% - #{$widthCalc})"};
  width:#{"-webkit-calc(50% - #{$widthCalc})"};
}
// CALCUL LARGEUR PAR 25
// exemple : ((53px))
@mixin w-25($widthCalc){
  width:calc#{"(25% - #{$widthCalc})"};
  width:-moz-calc#{"(25% - #{$widthCalc})"};
  width:-webkit-calc#{"(25% - #{$widthCalc})"};
  width:#{"calc(25% - #{$widthCalc})"};
  width:#{"-moz-calc(25% - #{$widthCalc})"};
  width:#{"-webkit-calc(25% - #{$widthCalc})"}; 
}
// CALCUL LARGEUR PAR 33
// exemple : ((53px))
@mixin w-33($widthCalc){
  width:calc#{"(33.3% - #{$widthCalc})"};
  width:-moz-calc#{"(33.3% - #{$widthCalc})"};
  width:-webkit-calc#{"(33.3% - #{$widthCalc})"};
  width:#{"calc(33.3% - #{$widthCalc})"};
  width:#{"-moz-calc(33.3% - #{$widthCalc})"};
  width:#{"-webkit-calc(33.3% - #{$widthCalc})"}; 
}
// VARIABLE BOTTOM (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin ab-b($ab-b){
  bottom:$ab-b;  
}

// VARIABLE TOP (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin ab-t($ab-t){
  top:$ab-t;  
}

// VARIABLE LEFT (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin ab-l($ab-l){
  left:$ab-l;  
}

// VARIABLE RIGHT (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin ab-r($ab-r){
  right:$ab-r;  
}

// VARIABLE CALCUL BOTTOM PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin ab-b50($ab-b50){
  bottom:calc#{"(50% - #{$ab-b50})"};
  bottom:-moz-calc#{"(50% - #{$ab-b50})"};
  bottom:-webkit-calc#{"(50% - #{$ab-b50})"};
  bottom:#{"calc(50% - #{$ab-b50})"};
  bottom:#{"-moz-calc(50% - #{$ab-b50})"};
  bottom:#{"-webkit-calc(50% - #{$ab-b50})"};
}

// VARIABLE CALCUL TOP PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin ab-t50($ab-t50){
  top:calc(50% - #{$ab-t50});
top:-moz-calc#{"(50% - #{$ab-t50})"};
  top:-webkit-calc#{"(50% - #{$ab-t50})"};
  top:#{"calc(50% - #{$ab-t50})"};
  top:#{"-moz-calc(50% - #{$ab-t50})"};
  top:#{"-webkit-calc(50% - #{$ab-t50})"};
}

// VARIABLE CALCUL LEFT PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin ab-l50($ab-l50){
  left:calc#{"(50% - #{$ab-l50})"};
  left:-moz-calc#{"(50% - #{$ab-l50})"};
  left:-webkit-calc#{"(50% - #{$ab-l50})"};
  left:#{"calc(50% - #{$ab-l50})"};
  left:#{"-moz-calc(50% - #{$ab-l50})"};
  left:#{"-webkit-calc(50% - #{$ab-l50})"};
}

//VARIABLE CALCUL RIGHT PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin ab-r50($ab-r50){
  right:calc#{"(50% - #{$ab-r50})"};
  right:-moz-calc#{"(50% - #{$ab-r50})"};
  right:-webkit-calc#{"(50% - #{$ab-r50})"};
  right:#{"calc(50% - #{$ab-r50})"};
  right:#{"-moz-calc(50% - #{$ab-r50})"};
  right:#{"-webkit-calc(50% - #{$ab-r50})"};
}

// POSITION ABSOLUTE + TOP + LEFT
// ex : (15px, 20px)
@mixin ab-tl($ab-t, $ab-l){
  position:absolute;
  top:$ab-t;
  left: $ab-l;
}
//  POSITION ABSOLU + TOP + LEFT CALC
// ex : (15px, (20px)
@mixin ab-tl50($ab-t, $ab-l50){
  position:absolute;
  @include ab-t ($ab-t);  
  @include ab-l50 ($ab-l50);
}

// POSITION ABSOLUTE + TOP + RIGHT
// ex : (15px, 20px)
@mixin ab-tr($ab-t, $ab-r){
  position: absolute;
  @include ab-t ($ab-t);  
  @include ab-r ($ab-r);  
}

// POSITION ABSOLUTE + BOTTOM + LEFT
// ex : (15px, 20px)
@mixin ab-bl($ab-b, $ab-l){
  position: absolute;
  @include ab-b ($ab-b);  
  @include ab-l ($ab-l);  
}

// POSITION ABSOLUTE + BOTTOM + RIGHT
// ex : (15px, 20px)
@mixin ab-br($ab-b, $ab-r){
  position: absolute;
  @include ab-b ($ab-b);  
  @include ab-r ($ab-r);  
}
//  POSITION ABSOLU + BOTTOM + LEFT CALC
// ex : (15px, (20px)
@mixin ab-bl50($ab-b, $ab-l50){
  position:absolute;
  @include ab-b ($ab-b);  
  @include ab-l50 ($ab-l50);
}

// POSITION ABSOLU + TOP CALCUL PAR 50 + LEFT CALCUL PAR 50
// ex : ((10px),(25px))
@mixin ab-t50l50($ab-t50, $ab-l50){
  position: absolute;
  @include ab-t50 ($ab-t50);
  @include ab-l50 ($ab-l50);
}

// POSITION ABSOLU + BOTTOM CALCUL PAR 50 + LEFT CALCUL PAR 50
// ex : ((10px),(25px))
@mixin ab-b50l50($ab-b50, $ab-l50){
  position: absolute;
  @include ab-b50 ($ab-b50);
  @include ab-l50 ($ab-l50);
}

// ABSOLU + TOP CALCUL PAR 50 + LEFT ABSOLUTE
// ex : ((30px), 0);
@mixin ab-t50l($ab-t50, $ab-l){
  position: absolute;
  @include ab-t50 ($ab-t50);
  @include ab-l ($ab-l);
}

// ABSOLU + TOP CALCUL PAR 50 + RIGHT ABSOLUTE
// ex : ((30px), 0);
@mixin ab-t50r($ab-t50, $ab-r){
  position: absolute;
  @include ab-t50 ($ab-t50);
  @include ab-r ($ab-r);
}

// ELLIPSIS OVERFLOW
@mixin ellipsis(){
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

@mixin cover(){
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// OBJECTFIT
@mixin fit($valWidth, $valHeight){
  object-fit:cover;
  width:$valWidth;
  height:$valHeight;
}

@mixin border($border-color){
  border-width: 1px;
  border-style:solid;
  border-color:$border-color;
}

//TRANSITION KIT
@mixin transition($kind, $rapidity, $type){
  -moz-transition: $kind $rapidity $type;
  -o-transition: $kind $rapidity $type;
  -webkit-transition: $kind $rapidity $type;
  transition: $kind $rapidity $type;  
}

@mixin invisible(){
  visibility: hidden; 
  opacity: 0;
  @include transition(all, 0.5s, ease);	
}

@mixin invisible-h(){
  @include invisible();
  height: 0;
}

@mixin visible(){
  visibility: visible; 
  opacity: 1;
  @include transition(all, 0.5s, ease); 
}

@mixin visible-h(){
  @include visible();
  height: auto;
}
@mixin inherit(){
  position: relative;
  top: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
}