h2,
.h2 {
	font-size: 4rem;
	line-height: 5rem;
	@media #{$w992} {
		line-height: 4rem;
	}
	@media #{$w480} {
		font-size: 3.5rem;
		line-height: 3.8rem;
	}								
}
h3,
.h3 {
	font-size: 3rem;
	line-height: 5rem;
	@media #{$w992} {
		line-height: 3.6rem;
	}			
}
h4,
.h4 {
  	font-family: 'BrandingBold', sans-serif;
	font-size: 2.4rem;
	line-height: 2.8rem;							
}
h5,
.h5 {
	font-size: 4rem;
	line-height: 5.3rem;	
	font-family: 'Nothing You Could Do', cursive;	
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	display: block;
	margin: 0;
	padding: 0;
}
h2,
.h2,
h3,
.h3 {
	font-family: 'Yeseva One', cursive;
}