header {
	position: fixed;
	@include w-h(100%, 9.2rem);
	background-image: linear-gradient(to right, #222337, #2c2b42, #37334e, #433b59, #4f4365);
	/*@include transition(all, 0.5s, ease);*/
	z-index:9998;
	@media #{$w992} {
		height: 5.4rem;
	}							
	.header {
		.header-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 2.4rem;
			@media #{$w992} {
				padding-top: 1rem;
			}	
			@media #{$w650} {
				padding-top: .7rem;
			}						
			.connect {
				a {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					i {
						font-style: normal;
						@media #{$w650} {
							display: none;
						}							
					}
					span {
						@media #{$w650} {
							display: flex;
							justify-content: center;
							align-items: center;
							@include w-h-sim(4.2rem);
							@include radius(50%);
							background-color: #35314a;
						}						
					}
					img {
						margin-right: 1.8rem;
						@media #{$w650} {
							margin-right: 0;
						}											
					}
				}
			}
		}

	}		
}